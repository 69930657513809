import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import AppUrl from '../../RestAPI/AppUrl';
import axios from 'axios';

class Footer extends Component {
	constructor() {
		super();
	}

	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
		return (
			<Fragment>
				<footer className="bg-dark text-inverse">
					<div className="container py-4 py-md-7">
						<div className="row gy-6 gy-lg-0">
							<div className="col-md-4 col-lg-3">
								<div className="widget">
									<img className="mb-4" src="/logo.png" srcSet="/logo.png" alt="" />
									<p className="mb-4">
										© 2025 Guru Financial Corporation. <br className="d-none d-lg-block" />
										All rights reserved.
									</p>
									<nav className="nav social social-white">
										{/* <a href="#">
											<i className="fab fa-twitter" />
										</a>
										<a href="#">
											<i className="fab fa-facebook-f" />
										</a> */}
									</nav>
									{/* /.social */}
								</div>
								{/* /.widget */}
							</div>
							{/* /column */}
							<div className="col-md-4 col-lg-3">
								<div className="widget">
									<h4 className="widget-title text-white mb-3">Get in Touch</h4>
									<address className="pe-xl-15 pe-xxl-17">
										Guru Financial Corp. <br />
										10970 Arrow Rte, <br />
										Suite 211, <br />
										Rancho Cucamonga, <br />
										CA 91730, <br />
										USA,
										<br />
										BRE # : 01215524
									</address>
									<a href="info@gurufc.com">info@gurufc.com</a>
									<br /> +1 760-946-3060 (Office)
								</div>
								{/* /.widget */}
							</div>
							{/* /column */}
							<div className="col-md-4 col-lg-3">
								<div className="widget">
									<h4 className="widget-title text-white mb-3">Learn More</h4>
									<ul className="list-unstyled  mb-0">
										<li>
											<NavLink to="/">
												<a>Home</a>
											</NavLink>
										</li>
										<li>
											<NavLink to="/about-us">
												<a>About Us</a>
											</NavLink>
										</li>
										<li>
											<NavLink to="/services">
												<a>Services</a>
											</NavLink>
										</li>
										<li>
											<NavLink to="/contact-us">
												<a>Contact Us</a>
											</NavLink>
										</li>
									</ul>
								</div>
								{/* /.widget */}
							</div>
						</div>
						{/*/.row */}
					</div>
					{/* /.container */}
				</footer>
			</Fragment>
		);
	}
}

export default Footer;
