import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';

const Contact = () => {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Contact Us - Gurufc</title>
			</Helmet>

			<section className="wrapper bg-soft-primary">
				<div className="container py-md-5 py-3 text-center">
					<div className="row">
						<div className="col-xl-7 mx-auto mb-6">
							<h1 className="display-1 mb-3">Get in Touch</h1>
							<h3 className="mb-0">Got any questions? Don't hesitate to get in touch.</h3>
						</div>
						{/* /column */}
					</div>
					{/* /.row */}
				</div>
				{/* /.container */}
			</section>

			<Fade>
				<section className="wrapper bg-light">
					<div className="container py-14 py-md-16">
						<div className="row gx-md-8 gx-xl-12 gy-10 align-items-center">
							<div className="col-md-8 col-lg-6 offset-lg-0 col-xl-5 offset-xl-1 position-relative">
								<figure className="rounded">
									<img src="./assets/img/contact.png" srcSet="./assets/img/contact.png" alt="" />
								</figure>
							</div>
							{/*/column */}
							<div className="col-lg-6">
								<img src="./assets/img/icons/lineal/telemarketer.svg" className="svg-inject icon-svg icon-svg-md mb-4" alt="" />
								<h2 className="display-4 mb-8">Convinced yet? Let's connect with each other</h2>
								<h6 className="mb-5">For all your financial requirements, Call , Anil Srivastava (President)</h6>
								<div className="d-flex flex-row">
									<div>
										<div className="icon text-primary fs-28 me-6 mt-n1">
											{' '}
											<i className="fas fa-map-marker" />{' '}
										</div>
									</div>
									<div>
										<h5 className="mb-1">Address</h5>
										<address>
											Guru Financial Corp. <br />
											10970 Arrow Rte, <br />
											Suite 211, <br />
											Rancho Cucamonga, <br />
											CA 91730 <br />
											USA
										</address>
									</div>
								</div>
								<div className="d-flex flex-row mb-3">
									<div>
										<div className="icon text-primary fs-28 me-6 mt-n1">
											{' '}
											<i className="fas fa-phone-alt" />{' '}
										</div>
									</div>
									<div>
										<h5 className="mb-1">Contact</h5>
										<p className="mb-0">
											Tel :<a href="tel:+17609463060"> +1 760-946-3060 (Office)</a>
										</p>
										<p className="mb-0">Fax : +1 760-946-3075 </p>
										<p className="mb-0">
											Cell :<a href="tel:+17609530531">+1 760 953 0531</a>
										</p>
										<p className="mb-0">BRE : #01215524 </p>
									</div>
								</div>
								<div className="d-flex flex-row">
									<div>
										<div className="icon text-primary fs-28 me-6 mt-n1">
											{' '}
											<i className="fas fa-envelope" />{' '}
										</div>
									</div>
									<div>
										<h5 className="mb-1">E-mail</h5>
										<p className="mb-0">
											<a href="mailto::info@gurufc.com" className="link-body">
												info@gurufc.com
											</a>
										</p>
									</div>
								</div>
							</div>
							{/*/column */}
						</div>
						{/*/.row */}
					</div>
					{/* /.container */}
				</section>
				{/* /section */}
			</Fade>
		</>
	);
};

export default Contact;
